import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var colorPalette = ["#00D8B6", "#008FFB", "#FEB019", "#FF4560", "#775DD0"];
import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
export default {
  name: "dashboard",
  data: function data() {
    return {
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      transactionType: null,
      prioritySeries: [],
      priorityChartoptions: {
        stroke: {
          curve: "straight"
        },
        xaxis: {
          categories: []
        },
        tooltip: {
          followCursor: true
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: "top",
          horizontalAlign: "left"
        }
      },
      issueSeries: [],
      issueChartoptions: {
        dataLabels: {
          enabled: false
        },
        colors: colorPalette,
        labels: [],
        legend: {
          position: "left"
        }
      },
      issueDuration: "this_month",
      issueCountLoading: false,
      priorityDuration: "this_month",
      priorityCountLoading: false,
      countLoading: true,
      customerCountLoading: true,
      jobCountLoading: true,
      quotationCountLoading: true,
      durationList: [{
        text: "This Week",
        value: "this_week"
      }, {
        text: "This Month",
        value: "this_month"
      }, {
        text: "This Year",
        value: "this_year"
      }],
      customerDuration: "this_month",
      jobDuration: "this_month",
      quotationDuration: "this_month",
      bugCount: 0,
      customerCount: {
        count: 0,
        all_customers: 0,
        active_customers: 0,
        percentage: 0
      },
      quotationCount: {
        count: 0,
        percentage: 0,
        average_revenue: 0
      },
      jobsCount: {
        count: 0,
        all_jobs: 0,
        recurring_jobs: 0,
        percentage: 0
      },
      jobCount: {
        total_jobs: 0,
        total_open_jobs: 0,
        total_closed_jobs: 0,
        total_cancelled_jobs: 0
      },
      visitCount: {
        total_visits: 0,
        total_open_visits: 0,
        total_overdue_visits: 0,
        total_today_visits: 0,
        total_tomorrow_visits: 0,
        total_this_week_visits: 0,
        total_upcomming_visits: 0,
        total_inprogress_visits: 0,
        total_hold_visits: 0,
        total_completed_visits: 0,
        total_cancelled_visits: 0
      }
    };
  },
  methods: {
    createTransaction: function createTransaction(type) {
      this.transactionType = type;
      this.customerDialog = true;
    },
    resetAll: function resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog: function closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomer: function selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkTransactionType();

      if (!this.customerPerson || this.customerPerson <= 0) {
        this.customerPersonDialog = true;
      } else {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson: function selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkTransactionType();

      if ((!this.customerProperty || this.customerProperty <= 0) && this.transactionType != "quotation") {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty: function selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    selectCustomerBilling: function selectCustomerBilling(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    checkTransactionType: function checkTransactionType() {
      var _this = this;

      if (_this.transactionType === "quotation") {
        if (_this.customer > 0 && _this.customerPerson > 0 && _this.customerBilling > 0) {
          _this.$router.push(_this.getDefaultRoute("quotation.create", {
            query: {
              customer: _this.customer,
              contact_person: _this.customerPerson,
              billing: _this.customerBilling
            }
          }));
        }
      } else {
        if (_this.customer > 0 && _this.customerPerson > 0 && _this.customerProperty > 0) {
          _this.$router.push(_this.getDefaultRoute(_this.transactionType + ".create", {
            query: {
              customer: _this.customer,
              contact_person: _this.customerPerson,
              property: _this.customerProperty
            }
          }));
        }
      }
    },
    getDashboardCount: function getDashboardCount() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(GET, {
            url: "dashboard-count"
          }).then(function (_ref) {
            var data = _ref.data;
            resolve(data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardCustomerCount: function getDashboardCustomerCount() {
      var _this = this;

      _this.customerCountLoading = true;
      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(QUERY, {
            url: "dashboard-customer",
            data: {
              duration: _this.customerDuration
            }
          }).then(function (_ref2) {
            var data = _ref2.data;
            resolve(data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardJobCount: function getDashboardJobCount() {
      var _this = this;

      _this.jobCountLoading = true;
      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(QUERY, {
            url: "dashboard-job",
            data: {
              duration: _this.jobDuration
            }
          }).then(function (_ref3) {
            var data = _ref3.data;
            resolve(data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardQuotationCount: function getDashboardQuotationCount() {
      var _this = this;

      _this.quotationCountLoading = true;
      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(QUERY, {
            url: "dashboard-quotation",
            data: {
              duration: _this.quotationDuration
            }
          }).then(function (_ref4) {
            var data = _ref4.data;
            resolve(data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardPriorityCount: function getDashboardPriorityCount() {
      var _this = this;

      _this.priorityCountLoading = true;
      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(QUERY, {
            url: "dashboard-priority-graph",
            data: {
              duration: _this.priorityDuration
            }
          }).then(function (_ref5) {
            var data = _ref5.data;
            resolve(data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardIssueCount: function getDashboardIssueCount() {
      var _this = this;

      _this.issueCountLoading = true;
      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(QUERY, {
            url: "dashboard-issue-graph",
            data: {
              duration: _this.issueDuration
            }
          }).then(function (_ref6) {
            var data = _ref6.data;
            resolve(data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerCount: function getCustomerCount() {
      var _this = this;

      _this.getDashboardCustomerCount().then(function (_ref7) {
        var customer = _ref7.customer;

        if (customer) {
          _this.customerCount = customer;
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.customerCountLoading = false;
      });
    },
    getJobsCount: function getJobsCount() {
      var _this = this;

      _this.getDashboardJobCount().then(function (_ref8) {
        var job = _ref8.job;

        if (job) {
          _this.jobsCount = job;
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.jobCountLoading = false;
      });
    },
    getQuotationCount: function getQuotationCount() {
      var _this = this;

      _this.getDashboardQuotationCount().then(function (_ref9) {
        var quotation = _ref9.quotation;

        if (quotation) {
          _this.quotationCount = quotation;
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.quotationCountLoading = false;
      });
    },
    getPriorityGraph: function getPriorityGraph() {
      var _this = this;

      _this.getDashboardPriorityCount().then(function (_ref10) {
        var categories = _ref10.categories,
            prioritySeries = _ref10.prioritySeries;

        if (categories) {
          _this.priorityChartoptions.xaxis.categories = categories;
        }

        if (prioritySeries) {
          _this.prioritySeries = prioritySeries;
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.priorityCountLoading = false;
      });
    },
    getIssueGraph: function getIssueGraph() {
      var _this = this;

      _this.getDashboardIssueCount().then(function (_ref11) {
        var issueSeries = _ref11.issueSeries,
            labels = _ref11.labels;

        if (labels) {
          _this.issueChartoptions.labels = labels;
        }

        if (issueSeries) {
          _this.issueSeries = issueSeries;
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.issueCountLoading = false;
      });
    }
  },
  components: {
    CustomerDialog: CustomerDialog,
    CustomerPersonDialog: CustomerPersonDialog,
    CustomerPropertyDialog: CustomerPropertyDialog
  },
  mounted: function mounted() {
    var _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Dashboard"
    }]);

    _this.getCustomerCount();

    _this.getJobsCount();

    _this.getQuotationCount();

    _this.getPriorityGraph();

    _this.getIssueGraph();

    _this.getDashboardCount().then(function (_ref12) {
      var job = _ref12.job,
          visit = _ref12.visit,
          bug = _ref12.bug;

      if (job) {
        _this.jobCount = job;
      }

      if (visit) {
        _this.visitCount = visit;
      }

      if (bug) {
        _this.bugCount = bug;
      }
    }).catch(function (error) {
      _this.logError(error);
    }).finally(function () {
      _this.countLoading = false;
    });
  }
};